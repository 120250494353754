import { FiGithub } from "react-icons/fi";
import { RiLinkedinLine, RiTwitterFill } from "react-icons/ri";
import Skills from "../Skills";

const Introduction = () => {
  const github = "https://github.com/Neph-dev";
  const linkedin = "https://www.linkedin.com/in/nephthali-salam-2bab561b6/";
  const twitter = "https://twitter.com/Nephthali_Salam";

  return (
    <div
      data-aos="fade-in"
      className="introduction-container"
      datat-aos-duration="1500"
    >
      <div className="introduction-content">
        <div className="icons-list">
          <a href={github} rel="noreferrer" target="_blank">
            <FiGithub size={30} className="icon" />
          </a>
          <a href={linkedin} rel="noreferrer" target="_blank">
            <RiLinkedinLine size={30} className="icon" />
          </a>
          <a href={twitter} rel="noreferrer" target="_blank">
            <RiTwitterFill size={30} className="icon" />
          </a>
        </div>

        <div className="home-welcome-message-text">
          Hello visitor, last time I checked, I was Nephthali Salam. I am a
          software engineer with a passion for crafting seamless digital
          experiences. <br />
          <div style={{ marginTop: 15 }} />
          I have worked on a diverse range of projects, including mobile, web
          and desktop applications, as well as cutting-edge developments in
          blockchain and Web 3.0.
          <br />
          <div style={{ marginTop: 15 }} />
          I'm also a space enthusiast, a lifelong learner, and a lover of all
          things tech. <br />
          <div style={{ marginTop: 15 }} />
          Currently what do I do? Well, scroll down to find out more. <br />
        </div>

        <Skills />
      </div>
    </div>
  );
};

export default Introduction;
