import "./navigationBar.css";

const NavigationBar = () => {
  return (
    <div className="navigationBar">
      <div className="logo">Nephthali Salam</div>
    </div>
  );
};

export default NavigationBar;
