import { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";

import AOS from "aos";
import "aos/dist/aos.css";
import "./experience.css";
import { MdNavigateNext } from "react-icons/md";

import { experience } from "../../Data/data";
import Information from "../../Assets/information-button.png";

const Experience = () => {
  const [description, setDescription] = useState(
    "Select an experience to view its description.",
  );
  const [icon, setIcon] = useState(Information);
  const [fileName, setFileName] = useState("README.md");
  const [selectedExperience, setSelectedExperience] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  const handleExperienceClick = (experience) => {
    setSelectedExperience(experience);
    setDescription(experience.description);
    setIcon(experience.companyLogo);
    setFileName(experience.title + ".md");
  };

  return (
    <div className="experience-container">
      <div className="experience-content">
        <div className="experience-companies">
          {experience.map((experience, index) => (
            <div onClick={() => handleExperienceClick(experience)}>
              <div
                key={index}
                datat-aos-duration="1500"
                data-aos="fade-right"
                className="exp-element"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    src={experience?.companyLogo}
                    alt=""
                    className="exp-comp-logo"
                  />

                  <div>
                    <div
                      className={`${experience.companyName === selectedExperience?.companyName ? "exp-comp-role-active" : "exp-comp-role"}`}
                    >
                      {experience?.title}
                    </div>
                    <div
                      className={`${experience.companyName === selectedExperience?.companyName ? "exp-comp-company-active" : "exp-comp-company"}`}
                    >
                      {experience?.companyName}
                    </div>
                    <div className="exp-comp-role-location">
                      {experience.workTypeIcon}
                      {experience.location}
                    </div>
                    <div className="exp-comp-role-years">
                      {experience?.duration}
                    </div>
                  </div>

                  <div className="exp-comp-view-more">
                    <MdNavigateNext size={40} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="experience-description-container">
          <div className="experience-content-header">
            <img src={icon} alt="" className="experience-content-logo" />
            <span className="experience-content-title">{fileName}</span>
          </div>

          <div className="experience-description">
            <code>
              {!selectedExperience ? (
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString(description).pauseFor(50).start();
                  }}
                />
              ) : (
                description.map((experience, index) => (
                  <div key={index}>
                    <div className="experience-description-lg">
                      {experience.title}
                    </div>
                    <div style={{ marginTop: "1rem" }} />
                    <div className="experience-description-md">Details:</div>
                    <div style={{ marginTop: ".5rem" }} />
                    <div className="experience-description-md">
                      {experience.details}
                    </div>
                  </div>
                ))
              )}
            </code>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
