import NgomaDigitech from "../Assets/NgomaDIgitech.png";
import Transafrica from "../Assets/TransAfricaLogo.png";
import Convergenc3 from "../Assets/Convergenc3.png";
import Collinson from "../Assets/Collinson.jpg";
import Brickous from "../Assets/Brickous.png";

import { MdHomeWork } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";

export const experience = [
  {
    title: "CTO & Co-founder - Permanent",
    companyName: "Brickous",
    companyLogo: Brickous,
    duration: "May 2024 – Present",
    location: "Cape Town, South Africa",
    website: "https://www.brickous.com/",
    workTypeIcon: (
      <MdHomeWork size={20} color={"#ccc"} className="exp-work-type-icon" />
    ),
    description: [
      {
        title: "Brickous platform development.",
        details: `Brickous is a real estate investment platform that allows investors to own a fraction of a property. I am responsible for the development of the platform and the technology strategy of the company.\n\nLink to the platform: https://www.brickous.com/\nSign up here: https://app.brickous.com/`,
      },
    ],
  },
  {
    title: "Full Stack Engineer - Permanent",
    companyName: "Collinson",
    companyLogo: Collinson,
    duration: "May 2024 – Present",
    location: "Cape Town, South Africa",
    website: "https://www.collinsongroup.com/",
    workTypeIcon: (
      <MdHomeWork size={20} color={"#ccc"} className="exp-work-type-icon" />
    ),
    description: [
      {
        title: "Payment platform team.",
        details:
          "• All I can say for now is that I am working on a payment platform.",
      },
    ],
  },
  {
    title: "Software Engineer - Permanent",
    companyName: "Convergence Three",
    companyLogo: Convergenc3,
    duration: "February 2023 – April 2024",
    location: "Pretoria, South Africa",
    website: "https://www.convergenc3.com",
    workTypeIcon: (
      <MdHomeWork size={20} color={"#ccc"} className="exp-work-type-icon" />
    ),
    description: [
      {
        title: "Diverse teams.",
        details:
          "• Received the Best Engineer of the Year Award 2023.\n \n• Led the development of enterprise-level software for Old-Mutual and RBS representing 1/3 revenue of the company.\n \n• Led the development of the AgriQ software, working in collaboration with farmers to bring automation and profitable revenue in the South African wine industry.\n \n• Developed a total of 4 applications for the VodaPay Platform.\n \n• Conducted research on the future of technology in the airline industry for FlySafair and Saudi Airline, pushing them to have insights on the new technology trends with the aim to boost their revenue.\n \n• Worked intensively in cleaning, rewriting, and splitting into microservices a platform that makes work collaboration faster and more secured, actively used by 400 people.\n \n• Developed and conducted trainings on the DEVOPS methodology and Cloud Computing.",
      },
    ],
  },
  {
    title: "Software developer - Contract",
    companyName: "Trans Africa Group",
    companyLogo: Transafrica,
    duration: "June 2022 – December 2022",
    location: "Pretoria, South Africa",
    website: "https://www.trans-africa.co.za/",
    workTypeIcon: (
      <FaBuilding size={15} color={"#ccc"} className="exp-work-type-icon" />
    ),
    description: [
      {
        title: "Internal systems development.",
        details:
          "• Within the first 3 months, delivered 3 key software allowing the department to make positive revenues.\n \n• Worked closely with the insurance department to bring enterprise solutions for automated application approval systems.\n \n• Built multiple cross-platform mobile applications using React Native.\n \n• Deployed and maintained applications to Play Store as well as the App Store.",
      },
    ],
  },
  {
    title: "Full stack engineer - Contract",
    companyName: "NgomaDigitech",
    companyLogo: NgomaDigitech,
    duration: "October 2019 – March 2022",
    location: "Johannesburg, South Africa",
    website: "http://www.ngomadigitech.com",
    workTypeIcon: (
      <MdHomeWork size={20} color={"#ccc"} className="exp-work-type-icon" />
    ),
    description: [
      {
        title: "Vinco platform team.",
        details:
          "• Designed, Built and Maintained a Learning Management system used by 2 institutions in Botswana.\n \n• Migrated server infrastructure to Azure cloud, resulting in a 49% reduction in operational costs.",
      },
    ],
  },
];
