import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import AOS from "aos";
import "aos/dist/aos.css";

import "./home.css";

import NavigationBar from "../../Components/NavigationBar";
import Experience from "../../Components/Experience";
import Footer from "../../Components/Footer";
import Introduction from "../../Components/Home/Introduction";

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <div className="page">
      <Helmet>
        <title>Nephthali Salam</title>
        <meta name="description" content="" data-rh="true" />
        <link rel="canonical" href="/" />
      </Helmet>

      <NavigationBar />

      <div className="background-page" />
      <div className="profile-picture" />

      <div className="home-container">
        <Introduction />

        <section id="experience">
          <div
            data-aos="fade-in"
            datat-aos-duration="1500"
            className="section-title"
          >
            Professional Experience
          </div>
          <Experience />
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
