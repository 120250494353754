import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./skills.css";

import { IoIosCode } from "react-icons/io";
import { DiAppstore } from "react-icons/di";
import { BsFileEarmarkCode } from "react-icons/bs";

const Skills = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  });

  return (
    <div>
      <div className="skill-cards-container">
        <div>
          <div className="skill-card-container">
            <div className="skill-card">
              <IoIosCode className="skill-icon" />
            </div>
          </div>
        </div>

        <div>
          <div className="skill-card-container">
            <div className="skill-card">
              <DiAppstore className="skill-icon" />
            </div>
          </div>
        </div>

        <div>
          <div className="skill-card-container">
            <div className="skill-card">
              <BsFileEarmarkCode className="skill-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
